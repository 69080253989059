var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"registryData"},[_c('div',{staticClass:"vs-h500",staticStyle:{"margin-bottom":"32px"}},[_vm._v(" Dati di fatturazione ")]),_c('div',{staticClass:"vs-mb-6"},[_c('ValidationProvider',{attrs:{"name":"Nome azienda","rules":{required: true, regex: _vm.specialChar}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"type":"text","name":"companyName","label":"Nome azienda","size":"large","placeholder":"Inserisci il nome della tua azienda","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.userData.company),callback:function ($$v) {_vm.$set(_vm.userData, "company", $$v)},expression:"userData.company"}})]}}])})],1),_c('div',{staticClass:"vs-mb-6"},[_c('VsCheckbox',{attrs:{"text":"Dichiaro di essere una Pubblica Amministrazione","caption":"E di essere esente IVA per split payment","labelHidden":"","size":"large"},model:{value:(_vm.userData.splitPayment),callback:function ($$v) {_vm.$set(_vm.userData, "splitPayment", $$v)},expression:"userData.splitPayment"}})],1),_c('div',{staticClass:"vs-mb-6"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"type":"email","name":"email","label":"Inserisci la tua email","size":"large","placeholder":"Inserisci il tuo indirizzo email","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}})]}}])})],1),_c('div',{staticClass:"vs-mb-6"},[_c('ValidationProvider',{attrs:{"name":"Telefono","rules":"required|libphoneNumberMobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsPhoneInput',{attrs:{"error":errors.length > 0,"size":"large","label":"Numero di telefono","placeholder":"Inserisci il tuo numero di telefono","errorMessage":errors[0]},model:{value:(_vm.userData.phone),callback:function ($$v) {_vm.$set(_vm.userData, "phone", $$v)},expression:"userData.phone"}})]}}])})],1),_c('div',{staticClass:"vs-mb-6"},[_c('ValidationProvider',{attrs:{"name":"Nazione","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsSelect',{attrs:{"error":errors.length > 0,"size":"large","errorMessage":errors[0],"filterable":true,"label":"Nazione","options":_vm.countriesOptions},model:{value:(_vm.userData.address.country),callback:function ($$v) {_vm.$set(_vm.userData.address, "country", $$v)},expression:"userData.address.country"}})]}}])})],1),_c('div',{staticClass:"vs-mb-6"},[_c('ValidationProvider',{attrs:{"name":"Indirizzo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"type":"text","name":"address","label":"Indirizzo","size":"large","placeholder":"Inserisci il tuo indirizzo","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.userData.address.line1),callback:function ($$v) {_vm.$set(_vm.userData.address, "line1", $$v)},expression:"userData.address.line1"}})]}}])})],1),_c('div',{staticClass:"vs-mb-6 md:vs-flex vs-gap-x-6"},[_c('div',{staticClass:"vs-w-full vs-mb-6 md:vs-mb-0"},[_c('ValidationProvider',{attrs:{"name":"Cap","rules":_vm.civicRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-form-data-input vs-w-full",attrs:{"type":"text","name":"zip","label":"Cap","size":"large","placeholder":"Cap","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.userData.address.postal_code),callback:function ($$v) {_vm.$set(_vm.userData.address, "postal_code", $$v)},expression:"userData.address.postal_code"}})]}}])})],1),_c('div',{staticClass:"vs-w-full"},[_c('ValidationProvider',{attrs:{"name":"Città","rules":"alphaSpaces|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-form-data-input vs-w-full",attrs:{"type":"text","name":"city","label":"Città","size":"large","placeholder":"Città","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.userData.address.city),callback:function ($$v) {_vm.$set(_vm.userData.address, "city", $$v)},expression:"userData.address.city"}})]}}])})],1)]),(_vm.userData.address && _vm.userData.address.country === 'IT')?_c('div',{staticClass:"vs-mb-6"},[_c('ValidationProvider',{attrs:{"name":"Provincia","rules":{required: _vm.userData.address.country === 'IT', alphaSpaces: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsSelect',{attrs:{"label":"Provincia","size":"large","placeholder":"Inserisci la tua provincia","filterable":true,"noOptionsResultText":"Nessun risultato","error":errors.length > 0,"errorMessage":errors[0],"options":_vm.zonesOptions},model:{value:(_vm.userData.address.state),callback:function ($$v) {_vm.$set(_vm.userData.address, "state", $$v)},expression:"userData.address.state"}})]}}],null,false,2648554228)})],1):_vm._e(),_c('div',{staticClass:"vs-mb-6"},[_c('VsLabel',{attrs:{"size":"large"}},[_vm._v(" Partita IVA ")]),_c('div',{staticClass:"vs-flex vs-gap-4"},[_c('VsSelect',{staticClass:"vs-flex-none vs-w-24 md:vs-w-max",attrs:{"labelHidden":true,"size":"large","filterable":"","value":_vm.countryType,"name":"vat","options":_vm.fiscalCodes.map(function (el) {
                    return {
                        value: ((el.iso) + "-" + (el.value)),
                        label: ((el.iso) + " - " + (el.country) + " (" + (el.value) + ")"),
                    }
                })},on:{"input":function($event){_vm.countryType = $event}}}),_c('ValidationProvider',{attrs:{"vid":"iva","name":"Partita IVA","rules":_vm.vatRules,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-flex-auto",attrs:{"labelHidden":true,"placeholder":_vm.vatPlaceholder,"size":"large","value":_vm.userData.tax.value.toUpperCase(),"name":"vat","error":errors.length > 0,"errorMessage":errors[0]},on:{"input":function($event){_vm.userData.tax.value = $event.toUpperCase()}}})]}}])})],1)],1),_c('div',{staticClass:"vs-mb-6"},[_c('ValidationProvider',{attrs:{"name":"Codice fiscale aziendale","rules":"numeric|min:8|max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('VsInput',{attrs:{"type":"text","name":"fiscalData","label":"Codice fiscale aziendale (opzionale)","size":"large","placeholder":"Inserisci il tuo codice fiscale aziendale","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.userData.fiscalCode),callback:function ($$v) {_vm.$set(_vm.userData, "fiscalCode", $$v)},expression:"userData.fiscalCode"}})]}}])})],1),(!_vm.countryTaxNotIta)?_c('div',[(!_vm.userData.splitPayment)?_c('div',{staticClass:"vs-my-8"},[_c('div',{staticClass:"vs-flex"},[_c('VsRadio',{staticStyle:{"margin-right":"22.5px"},attrs:{"text":"Identificativo SDI","labelHidden":true,"size":"large","inputValue":"sdi","value":_vm.fiscalId},on:{"input":function($event){_vm.fiscalId = $event}}}),_c('VsRadio',{attrs:{"text":"PEC","labelHidden":true,"size":"large","inputValue":"pec","value":_vm.fiscalId},on:{"input":function($event){_vm.fiscalId = $event}}})],1)]):_vm._e(),(_vm.fiscalId === 'sdi' || _vm.userData.splitPayment)?_c('div',{staticClass:"vs-mb-6"},[_c('ValidationProvider',{attrs:{"name":"Identificativo SDI","rules":{
                    required: (_vm.fiscalId === 'sdi' && !_vm.countryTaxNotIta),
                    length: _vm.userData.splitPayment ? 6 : 7,
                    alpha_num: true
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('VsInput',{attrs:{"type":"text","name":"sdi","label":"Identificativo SDI","size":"large","placeholder":"Inserisci il tuo identificativo SDI","error":errors.length > 0,"errorMessage":errors[0],"value":_vm.userData.sdi},on:{"input":function($event){_vm.userData.sdi = $event.toUpperCase()}}})]}}],null,false,4263054111)})],1):_vm._e(),(_vm.fiscalId === 'pec' && !_vm.userData.splitPayment)?_c('div',{staticClass:"vs-mb-6"},[_c('ValidationProvider',{attrs:{"name":"PEC","rules":{required: (_vm.fiscalId === 'pec' && !_vm.countryTaxNotIta), email: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('VsInput',{attrs:{"type":"text","name":"pec","label":"PEC","size":"large","placeholder":"Inserisci la tua PEC","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.userData.pec),callback:function ($$v) {_vm.$set(_vm.userData, "pec", $$v)},expression:"userData.pec"}})]}}],null,false,2287011914)})],1):_vm._e()]):_vm._e(),(!_vm.hideSaveButton)?_c('VsButton',{staticClass:"vs-mt-8",attrs:{"size":"large","disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.submitUserData}},[_vm._v(" Salva ")]):_vm._e(),(_vm.loading)?_c('VsLoader',{attrs:{"overlay":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }