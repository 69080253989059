











































































































































































































































































































import MyAccordion from '@/components/MyAccordion/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { get } from 'lodash'
import { zonesOptions, sectorType, fiscalCodes, countries } from '@/utils/constants'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { createOrUpdateInvoicesData } from '@/api/shop'
import { CustomerData } from '@/api/shop/types/shop'

@Component({
    name: 'VsInvoiceData',
    components: {
        MyAccordion,
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({ default: null, required: true }) customer!: CustomerData
    @Prop({ default: false, required: false }) hideSaveButton!: boolean

    private fiscalId = 'sdi'
    private loading = false
    private countryTaxNotIta = false
    private userData: CustomerData = {
        email: '',
        company: '',
        phone: '',
        address: {
            city: '',
            country: 'IT',
            line1: '',
            line2: '',
            postal_code: '',
            state: '',
        },
        sdi: '',
        pec: '',
        tax: {
            country: 'IT',
            exempt: false,
            type: 'eu_vat',
            value: '',
        },
        fiscalCode: '',
        splitPayment: false,
    }

    $refs!: any

    get zonesOptions (): any[] {
        return zonesOptions
    }

    get sectorType (): any[] {
        return sectorType
    }

    get fiscalCodes (): any[] {
        return fiscalCodes
    }

    get specialChar () {
        return /^[A-Z0-9@&'".,\-àèìòù\s]*$/i
    }

    get civicRules () {
        if (this.userData.address && this.userData.address.country && this.userData.address.country === 'IT') {
            return 'required|min:5|max:5|numeric'
        } else {
            return 'required'
        }
    }

    get countriesOptions () {
        return countries.map((country) => {
            return {
                value: country.code,
                label: country.name,
            }
        })
    }

    get vatPlaceholder () {
        if (this.userData.tax.country) {
            const code = this.fiscalCodes.find(el => el.iso === this.userData.tax.country)
            if (code) {
                return `es: ${code.example}`
            }
        }
        return ''
    }

    get vatRules () {
        if (this.userData.tax.country && countries) {
            const country = countries.find(el => el.code === this.userData.tax.country)
            if (country) {
                return {
                    required: true,
                    regex: country.vat_regex ? `^${country.vat_regex}$` : false,
                }
            }
        }
        return {
            required: true,
        }
    }

    get countryType () {
        return `${this.userData.tax.country}-${this.userData.tax.type}`
    }

    set countryType (val: string) {
        const [country, type] = val.split('-')
        this.userData.tax.country = country
        this.userData.tax.type = type
    }

    private doubleCheckRequired (field1: string, field2: string) {
        if (field1 && field1.trim() !== '') {
            return false
        }
        if (field2 && field2.trim() !== '') {
            return false
        }
        return true
    }

    @Watch('userData.tax.country', { immediate: true })
    private checkTaxCountry (value: string) {
        if (value !== 'IT') {
            this.countryTaxNotIta = true
            this.userData.sdi = 'XXXXXXX'
            this.userData.pec = ''
        } else {
            this.countryTaxNotIta = false
            this.userData.sdi = ''
            this.userData.pec = ''
        }
    }

    @Watch('customer', { immediate: true, deep: true })
    private setUserData (val: any) {
        if (!val) return
        this.userData = JSON.parse(JSON.stringify(val))
        if (!this.userData.address) {
            this.userData.address = {
                city: '',
                country: 'IT',
                line1: '',
                line2: '',
                postal_code: '',
                state: '',
            }
        }
    }

    private sanitazeUserData () {
        if (this.userData.splitPayment) {
            this.userData.pec = ''
        }
        if (!this.userData.splitPayment && !this.countryTaxNotIta) {
            if (this.fiscalId === 'pec') this.userData.sdi = '0000000'
            if (this.userData.pec === null || this.fiscalId !== 'pec') this.userData.pec = ''
        }

        if (this.userData.address.country !== 'IT') this.userData.address.state = ''
    }

    public async submitUserData () {
        const valid = await this.$refs.registryData.validate()
        if (!valid) {
            this.$root.$vsToast({
                heading: 'Compila tutti i campi obbligatori',
                aspect: VsToastAspectEnum.alert,
                timeout: 5000,
            })
            return
        }
        this.loading = true
        try {
            this.sanitazeUserData()
            await createOrUpdateInvoicesData(this.userData)
            this.$root.$vsToast({
                heading: 'Dati salvati con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 5000,
            })
            this.$emit('submit')
        } catch (error) {
            const errorCode = get(error, 'response.data.code', '')
            switch (errorCode) {
                case 'tax_id_invalid':
                    this.$refs.registryData.setErrors({
                        vat: ['L\'identificativo fiscale non è valido.'],
                    })
                    this.$root.$vsToast({
                        heading: 'L\'identificativo fiscale non è valido.',
                        aspect: VsToastAspectEnum.alert,
                        timeout: 5000,
                    })
                    break
                case 'resource_already_exists':
                    this.$refs.registryData.setErrors({
                        vat: ['Esiste già un identificativo fiscale con il valore inserito.'],
                    })
                    this.$root.$vsToast({
                        heading: 'Esiste già un identificativo fiscale con il valore inserito.',
                        aspect: VsToastAspectEnum.alert,
                        timeout: 5000,
                    })
                    break
                default:
                    this.$root.$vsToast({
                        heading: 'Errore nel salvataggio dei dati.',
                        aspect: VsToastAspectEnum.alert,
                        timeout: 5000,
                    })
            }
        }
        this.loading = false
    }
}
