





import { Component, Vue } from 'vue-property-decorator'
import { getInvoiceData } from '@/api/shop'
import { CustomerData } from '@/api/shop/types/shop'
import VsInvoiceData from '@/components/VsInvoiceData/Index.vue'

@Component({
    name: 'InvoiceData',
    components: {
        VsInvoiceData,
    },
})
export default class extends Vue {
    private userData: CustomerData = {
        email: '',
        company: '',
        phone: '',
        address: {
            city: '',
            country: 'IT',
            line1: '',
            line2: '',
            postal_code: '',
            state: '',
        },
        sdi: '',
        pec: '',
        tax: {
            country: 'IT',
            exempt: false,
            type: 'eu_vat',
            value: '',
        },
        fiscalCode: '',
        splitPayment: false,
    }

    async beforeMount () {
        await this.getInvoiceData()
    }

    private async getInvoiceData () {
        try {
            const resp = await getInvoiceData()
            this.userData = resp.data
        } catch (e) {
            console.log(e)
        }
    }
}
